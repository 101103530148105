import * as React from 'react';
import styled from '@emotion/styled';
import HomeHero from '../images/bg/home.jpg';

const MainHero = styled.div`
  width: 100%;
  height: 450px;
  overflow: hidden;
  background: url(${HomeHero}) no-repeat center bottom;
  background-size: cover;
  margin-bottom: 64px;
`;

const HeroImageA = () => (
  <>
    <MainHero />
  </>
);

export default HeroImageA;