import * as React from 'react';
// import { wrapper } from './siteWidthWrapper.module.css'
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  max-width: var(--site-width);
  margin: ${props => props.margin || '3em auto'};
`;

const SiteWidthWrapper = ({children}) => {
  return (
    <>
      <Wrapper>{children}</Wrapper>
    </>
  );
};

export default SiteWidthWrapper;
