import * as React from 'react';
import styled from '@emotion/styled';
import StyledButton from '../components/button';

const CenterMe = styled.div`
  display: flex;
  justify-content: center;
`;

const LinkStyle = styled.a`
  text-decoration: none;
  margin-top: 32px;
  font-family: Oswald, sans-serif;
  font-weight: bold;
  display: block;
`;

const CenteredButton = ({children}) => (
    <>
    <CenterMe>
        <LinkStyle href='tel:+17788591917' title='call top1 painting'>
            <StyledButton>{children}</StyledButton>
        </LinkStyle>
    </CenterMe>
    </>
);

export default CenteredButton;