import * as React from 'react';
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import styled from '@emotion/styled';
import Layout from '../../components/layout';
import Seo from '../../components/seo';
import SiteWidthWrapper from '../../components/wrappers/siteWidthWrapper';
import HeroImageA from '../../components/MainHero';
import CenteredButton from '../../components/centeredButton';

const FlexTwo = styled.div`
  display: grid;


  @media (min-width: 1000px) {
    grid-template-columns: 2fr 1fr;
  }
`;

const ExteriorPaintingServices = () => (
    <Layout>
		<Seo title='Top1 Exterior Painting'
         description='Professional interior painting services' />

      {/* Hero Image is found in /components/MainHero.js */}
      <HeroImageA />
		
		<SiteWidthWrapper>
			<FlexTwo>
				<div>
					<h1>Exterior Painting &amp; Renovations</h1>
					<p>We have a wide range of quality paint colours to ensure that your exterior paint can withstand BC’s weather conditions and will last throughout all seasons! Selecting and using beautiful exterior colours allows you to express your personal style and fit in with your neighborhood. Choosing the right colours is the first order of business when planning to paint. It is best to choose colours that are in harmony with the architecture of your house and neighborhood. Exterior Painting can help with the value of your home. At Top1 Painting we deliver the quality results you expect from a painting company.</p>
					<p>You can keep on living while we do the work on your home because we keep our work areas clean. You can also rest easy knowing that we never leave a mess and always put things back the way you want them.</p>
					<p>Give us a call to make an appointment to discuss how our fast and experienced exterior painters can help you.</p>
					<CenteredButton>Call Now</CenteredButton>
				</div>
				<div>
					<h2>Exterior Painting Services</h2>
					<ul>
						<li>Stucco and Concrete</li>
							<li>Doors and Windows</li>
							<li>Wood and Vinyl Shutters</li>
							<li>Garage Doors and Entry Doors</li>
							<li>Decks and Patio Covers</li>
							<li>Wrought Iron Fences</li>
							<li>Railings</li>
					</ul>
				</div>
			</FlexTwo>
		</SiteWidthWrapper>
	</Layout>
);

export default ExteriorPaintingServices;
