import * as React from 'react';
import styled from '@emotion/styled';

const Button = styled.div`
  cursor: pointer;
  font-family: Oswald, sans-serif;
  background: var(--blue);
  color: white;
  text-align: center;
  border: none;
  padding: 24px;

  // centers the button
  margin: 0 auto 32px auto;
  display: inline-block;
`;

const StyledButton = ({children}) => {
  return (
      <Button>{children}</Button>
  );
};

export default StyledButton;